<template>
  <AtomsGlobalDialog :is-open="dialog?.isOpen" :is-message-container="dialog?.isMessageContainer" @close="close" class="cart-items-removed">
    
      <!-- heading -->

      <strong class="block mb-8 pb-8 uppercase font-bold text-2xl border-b border-neutral-200 tablet:px-8">{{ $t('Odebrané zboží z košíku') }}</strong>

      <!-- removed items without details -->

      <strong v-if="itemsRemovedWithoutDetails?.length" class="block mb-8">{{ $t('Některé z produktů, které jste měli v košíku, nejsou v aktuální nabídce a musely být odebrány. Prosíme, ověřte aktuální obsah košíku.') }}</strong>

      <!-- modified quantity -->

      <template v-if="itemsWithModifiedQuantity?.length">
        <strong class="block mb-8 text-error-500">{{ $t('Z důvodu nedostupnosti jsme museli u následujících položek snížit počet kusů v košíku') }}:</strong>

        <div class="cart-items-removed__item" v-for="item in itemsWithModifiedQuantity">
          <div class="cart-items-removed__item__image">
            <AtomsGlobalLink :to="item.url" class="cart-item__image">
              <MoleculesGlobalWareImage :image-id="item.imageId" suffix="pl" />
            </AtomsGlobalLink>
          </div>
          <div class="cart-items-removed__item__details">
            <AtomsGlobalLink :to="item.url" class="font-bold text-xl underline tracking-normal">
              {{ item.name }}
            </AtomsGlobalLink>
            <div class="text-lg">{{ item.variantName }}</div>
            <div v-if="item.code" class="text-sm text-neutral-300">{{ item.code }}</div>
          </div>
          <div class="cart-items-removed__item__quantities">
            <div class="font-bold">{{ $t('Aktuální počet') }}: {{ item.newCount }}</div>
            <div>{{ $t('Původní počet') }}: {{ item.originalCount }}</div>
          </div>
        </div>

      </template>

      <!-- removed items -->

      <template v-if="removedItems?.length">
        <strong v-if="!itemsWithModifiedQuantity?.length" class="block mb-8 text-error-500">{{ $t('Z důvodu nedostupnosti jsme museli následující položky odebrat z košíku') }}:</strong>
        <strong v-else class="block mt-8 mb-8 text-error-500">{{ $t('A následující položky zcela z košíku odebrat') }}:</strong>

        <div class="cart-items-removed__item" v-for="item in removedItems">
          <div class="cart-items-removed__item__image">
            <AtomsGlobalLink :to="item.url" class="cart-item__image">
              <MoleculesGlobalWareImage :image-id="item.imageId" suffix="pl" />
            </AtomsGlobalLink>
          </div>
          <div class="cart-items-removed__item__details">
            <AtomsGlobalLink :to="item.url" class="font-bold text-xl underline tracking-normal">
              {{ item.name }}
            </AtomsGlobalLink>
            <div class="text-lg">{{ item.variantName }}</div>
            <div v-if="item.code" class="text-sm text-neutral-300">{{ item.code }}</div>
          </div>
        </div>
      </template>

      <!-- button -->

      <div class="flex justify-end pt-8">
        <MoleculesButtonsButton :href="cache.getModuleUrl('modules/order/cart')" :arrow-right="true" @click="close">{{ $t('Přejít do košíku') }}</MoleculesButtonsButton>
      </div>
    
  </AtomsGlobalDialog>
</template>
<script setup>

const cache = useCache();

const dialog = computed(() => useDialog().get('cartItemsRemoved'));

const itemsWithModifiedQuantity = computed(() => dialog.value?.data?.filter(item => item.newCount));
const removedItems = computed(() => dialog.value?.data?.filter(item => item.newCount === 0));
const itemsRemovedWithoutDetails = computed(() => removedItems.value?.filter(item => !item.name && !item.url));

const close = () => {
  useDialog().close();
}

</script>
<style lang="postcss">
.cart-items-removed {
  @apply w-[700px] desktop:w-[950px];

  &__item {
    @apply flex flex-wrap gap-x-8 gap-y-4 items-center border-b pb-8 border-neutral-200;

    &__details {
      @apply w-full tablet:flex-1;
    }
  }
}
</style>